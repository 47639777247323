html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @include body-font();
}

#app {
  display: flex;
  flex-direction: row;

  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--ac-app-background-color);
  overflow: hidden;
}

#app > main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
