@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes tick-move {
  0% {
    transform: translateY(200%);
    right: 0%;
  }
  20% {
    transform: translateY(100%);
    right: 20%;
  }
  40% {
    transform: translateY(300%);
    right: 40%;
  }
  40% {
    transform: translateY(300%);
    right: 50%;
  }
  60% {
    transform: translateY(200%);
    right: 60%;
  }
  70% {
    transform: translateY(200%);
    right: 60%;
  }
  80% {
    transform: translateY(300%);
    right: 80%;
  }
  100% {
    transform: translateY(200%);
    right: 100%;
  }
}

@keyframes noise-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i * calc(1 / $steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}

.ac-tikjas-glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: red;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.ac-tikjas-glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 3s infinite linear alternate-reverse;
}
.ac-tikjas {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: italic;

  &-tick {
    position: absolute;
    right: 0;
    animation: tick-move 2s ease-in-out infinite;
  }
}
