@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-hud {
  position: fixed;
  bottom: -4px;
  left: -4px;
  height: 5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 30px -20px 20px rgba(0, 0, 0, 0.8);
  border-radius: 50% 50% 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid hsla(0, 0%, 100%, 0.1);

  input {
    color: white;
    background-color: transparent;
    border: 2px solid hsla(0, 0%, 100%, 0.4);
    height: 3rem;
    font-family: inherit;
    padding: 0.25rem 2rem;
    text-align: center;
    max-width: 8rem;
    font-size: 1.2rem;
  }
}
