@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-text-input-wrapper {
  border: var(--ac-pixel-size-1) solid var(--ac-color-transparent);
  border-radius: var(--ac-pixel-size-9);

  transition: outline 0.2s ease;
  outline: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);
  background-color: var(--ac-color-neutral-white);

  &--dense {
    height: 1rem;
  }

  &--error {
    outline-color: var(--ac-color-feedback-error);
  }

  &:hover,
  &:focus-within {
    outline-color: var(--ac-color-primary-500);
  }
  &:disabled {
    outline-color: var(--ac-color-neutral-200);
  }
}

.ac-text-input {
  padding: 0.75rem;
  height: 1.5rem;
  width: 100%;

  border: none;
  outline: none;
  background: none;

  @include body-font();
}
