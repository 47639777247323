@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes scale {
  from {
    opacity: 0;
    transform: scale(0.75) translateY(25%);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
.ac-fight {
  &_modal {
    max-height: 40vh;
    max-width: 60rem;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 1em;
    background: url(~@assets/images/blurp_bg.svg);
    box-shadow: 0 0 1.875rem;
    border: 0;
    z-index: 2;
    animation: scale 0.2s ease forwards;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    overflow: hidden;

    &::backdrop {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  &-versus {
    display: inline-block;
    padding: 1.5em;
    position: relative;
    top: -60px;
  }
}
