@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-icon {
    display: inline-block;

    path {
        fill: inherit;
    }

    &--sm {
        width: 1rem;
    }

    &--md {
        width: 1.25rem;
    }
    &--lg {
        width: 1.75rem;
        height: 1.75rem;
    }
}
