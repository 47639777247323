@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-employee-card {
  height: 11.25rem;
  width: 7.5rem;
  text-align: center;
  align-items: unset;
  transition: transform 0.25s ease, box-shadow 0.5s ease;
  color: black;
  font-family: inherit;
  font-size: 1rem;
  border: none;
  box-sizing: content-box;
  flex-direction: column;
  justify-content: flex-start;

  transform: rotateX(8deg) rotateY(0deg) translate3d(0, 1px, 0) scale(1);
  cursor: pointer;

  background-image: -webkit-repeating-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(0, 0%, 100%, 0) 6%,
      hsla(0, 0%, 100%, 0.1) 7.5%
    ),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0) 4%, hsla(0, 0%, 0%, 0.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 1.2%, hsla(0, 0%, 100%, 0.15)
          2.2%),
    linear-gradient(180deg, hsl(0, 0%, 78%) 0%, hsl(0, 0%, 90%) 47%, hsl(0, 0%, 78%) 53%, hsl(0, 0%, 70%) 100%);
  background-color: hsl(0, 0%, 90%);
  box-shadow: /*border */ inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px,
    /* soft SD         */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px,
    /* bottom SD       */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px,
    /* top HL          */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px,
    /* outer HL        */ hsla(65, 100%, 8%, 0.8) 0 5px 12px 2px;

  padding: 4px;

  &::after {
    content: "";
    display: block;
    color: hsl(0, 100%, 50%);
    position: absolute;
    top: -12.5%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    opacity: 0;
    transform: rotate(0) scale(4);
    transition: all 0.25s cubic-bezier(0.85, 0, 0.15, 1);
    pointer-events: none;
  }

  & > img {
    width: 100%;
    height: 8.75rem;
    object-fit: cover;
  }

  &:hover {
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 1px, 0) scale(1.1);
    box-shadow: /*border */ inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px,
      /* soft SD         */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px,
      /* bottom SD       */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px,
      /* top HL          */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px,
      /* outer HL        */ hsla(65, 100%, 32%, 0.8) 0 0px 12px 2px;
  }

  &--hero {
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 1px, 0) scale(1.1);
    box-shadow: /*border */ inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px,
      /* soft SD         */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px,
      /* bottom SD       */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px,
      /* top HL          */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px,
      /* outer HL        */ hsl(117, 71%, 45%) 0 0px 12px 2px;
  }

  &--victim {
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 1px, 0) scale(1.1);
    box-shadow: /*border */ inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px,
      /* soft SD         */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px,
      /* bottom SD       */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px,
      /* top HL          */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px,
      /* outer HL        */ hsl(0, 60%, 26%) 0 0px 12px 2px;

    &.ac-employee-card--stamped::after {
      content: "GETIKT";
      opacity: 1;
      transform: rotate(-12deg) scale(1);
    }
  }
}
