//General font-size mixin. pass css variable as size
@mixin font-size($size) {
    font-size: $size;
}

//Mixin to include body font
@mixin body-font() {
    font-family: "Republica Minor", sans-serif;
    font-weight: var(--ac-typography-font-weight-regular);
    color: var(--ac-color-neutral-white);
}

//Mixin to include title font
@mixin title-font() {
    font-family: "Republica Minor", sans-serif;
    font-weight: var(--ac-typography-font-weight-semibold);
    color: var(--ac-color-neutral-white);
}

@mixin button-transitions() {
    transition:
        color 0.2s ease,
        background-color 0.15s ease-out,
        fill 0.2s ease;
}

@mixin define-color($title, $color) {
    #{$title}: #{$color};
    #{$title}-rgb:
        #{red($color)},
        #{green($color)},
        #{blue($color)};
}

@function use-alpha($title, $alpha: 1) {
    @return rgba(var(#{$title}-rgb), $alpha);
}
