@import "../../styles/globals/breakpoints";
@import "../../styles/globals/mixins";
@import "../../styles/globals/variables";
@import "../../styles/globals/grid";

@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.ac-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  text-align: center;

  & &-subtitle {
    color: use-alpha("--ac-color-neutral-white", 0.45);
    span {
      color: var(--ac-color-neutral-white);
      animation: blink 0.4s linear infinite alternate-reverse;
    }
  }
}
