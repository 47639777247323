@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-card {
  width: 100%;
  border-radius: var(--ac-pixel-size-12);

  background-color: var(--ac-color-neutral-white);
  box-shadow: var(--ac-box-shadow-xs);
}
