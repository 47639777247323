@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-employee-card-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  max-width: 75vw;
  width: 100%;

  @media #{$breakpoint-md-up} {
    perspective: 1500px;
  }
}
