@import "../../styles/globals/breakpoints";
@import "../../styles/globals/mixins";
@import "../../styles/globals/variables";
@import "../../styles/globals/grid";

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.08);
  }
  to {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1.09);
  }
}

.ac-default-background {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-image: url(~@assets/images/tj_bg_flat.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 96px 96px 51px 0 var(--ac-app-background-color) inset,
    -96px -96px 51px 0 var(--ac-app-background-color) inset;
  aspect-ratio: 2150/1382;
  max-width: 200rem;
  left: 50%;
  top: 50%;
  animation: fade-in 2s 0.5s ease forwards;
}

.ac-default-layout {
  z-index: 1;
}
