@font-face {
    font-family: "Republica Minor";
    src: local("Republica Minor Regular"), local("Republica-Minor-Regular"),
        url("~@assets/fonts/Republica-Minor-Regular.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Republica Minor";
    src: local("Republica Minor Bold Italic"), local("Republica-Minor-Bold-Italic"),
        url("~@assets/fonts/Republica-Minor-Bold-Italic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
