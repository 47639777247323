@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-button {
    position: relative;
    padding: var(--ac-pixel-size-14) var(--ac-pixel-size-16);
    border: var(--ac-pixel-size-1) solid var(--ac-color-transparent);
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;
    @include button-transitions();

    &-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &-tooltip {
        position: absolute;
        display: inline-block;
        background-color: var(--ac-color-neutral-900);
        padding: var(--ac-pixel-size-8);
        border-radius: var(--ac-pixel-size-7);
        top: calc(100% + var(--ac-pixel-size-8));
        left: 0;
        right: 0;
        margin: 0 auto;
        width: max-content;
        color: var(--ac-color-neutral-white);
        @include title-font();
        @include font-size(var(--ac-pixel-size-8));
    }

    // DEFAULT VARIANT

    &-variant-default {
        border-radius: var(--ac-pixel-size-9);

        padding: 3rem 4rem;

        span {
            font-size: 2.5rem !important;
        }

        // DEFAULT PRIMARY

        &-color-primary {
            background-color: hsla(0, 0%, 100%, 0.1);
            border: 2px solid hsla(0, 0%, 100%, 0.1);
            color: var(--ac-color-neutral-white);
            fill: var(--ac-color-neutral-white);

            &:hover {
                background-color: var(--ac-color-primary-700);
            }

            &:focus-visible {
                outline: var(--ac-pixel-size-4) solid var(--ac-color-primary-300);
            }
        }

        // DEFAULT SECONDARY

        &-color-secondary {
            background-color: var(--ac-color-neutral-white);
            border-color: var(--ac-color-neutral-500);
            color: var(--ac-color-neutral-900);
            fill: var(--ac-color-neutral-900);

            &:hover {
                color: var(--ac-color-neutral-700);
                fill: var(--ac-color-neutral-700);
                background-color: var(--ac-color-secondary-100);
            }

            &:focus-visible {
                outline: var(--ac-pixel-size-4) solid var(--ac-color-secondary-100);
            }
        }

        // DEFAULT WITH ICON

        &--has-icon {
            padding: var(--ac-pixel-size-14) var(--ac-pixel-size-24) var(--ac-pixel-size-14) var(--ac-pixel-size-16);

            .ac-button-icon {
                margin-right: var(--ac-pixel-size-10);
            }
        }

        // DEFAULT DISABLED

        &--disabled {
            background-color: var(--ac-color-neutral-400);
            border-color: var(--ac-color-neutral-400);
            pointer-events: none;
            color: var(--ac-color-neutral-200);
            fill: var(--ac-color-neutral-200);
        }
    }

    // TEXT VARIANT

    &-variant-text {
        padding: 0;
        border: 0;
        background-color: var(--ac-color-transparent);

        // TEXT PRIMARY

        &-color-primary {
            color: var(--ac-color-primary-500);
            fill: var(--ac-color-primary-500);

            &:hover {
                color: var(--ac-color-primary-700);
                fill: var(--ac-color-primary-700);
            }
        }

        // TEXT WITH ICON

        &--has-icon {
            .ac-button-icon {
                margin-right: var(--ac-pixel-size-10);
            }
        }

        // TEXT DISABLED

        &--disabled {
            pointer-events: none;
            color: var(--ac-color-neutral-400);
            fill: var(--ac-color-neutral-400);
        }
    }

    // ICON VARIANT

    &-variant-icon {
        padding: var(--ac-pixel-size-10);
        border-radius: 50%;
        background-color: var(--ac-color-transparent);
        fill: var(--ac-color-neutral-900);

        // ICON PRIMARY

        &-color-primary {
            &:hover,
            &:focus {
                background-color: rgba(249, 131, 76, 0.08);
                fill: var(--ac-color-primary-500);
            }

            &:focus-visible {
                outline: none;
            }
        }

        // ICON SECONDARY

        &-color-secondary {
            &:hover,
            &:focus-visible {
                background-color: rgba(77, 102, 99, 0.08);
            }

            &:active {
                background-color: rgba(77, 102, 99, 0.16);
            }

            &:focus-visible {
                outline: none;
            }
        }

        // ICON DISABLED

        &--disabled {
            pointer-events: none;
            color: var(--ac-color-neutral-400);
            fill: var(--ac-color-neutral-400);
        }
    }
}
