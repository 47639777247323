:root {
    /* ---- Typography ---- */

    //Font-size
    --ac-typography-font-size-28: 1.75rem;
    --ac-typography-font-size-20: 1.25rem;
    --ac-typography-font-size-16: 1rem;
    --ac-typography-font-size-14: 0.875rem;
    --ac-typography-font-size-12: 0.75rem;

    //Line-height
    --ac-typography-line-height-36: 36px;
    --ac-typography-line-height-28: 28px;
    --ac-typography-line-height-24: 24px;
    --ac-typography-line-height-20: 20px;

    //Font-weight
    --ac-typography-font-weight-semibold: 600;
    --ac-typography-font-weight-regular: 400;
    --ac-typography-font-weight-light: 300;

    /* ---- Color ---- */

    //Primary
    @include define-color("--ac-color-primary-50", #fcebe3);
    @include define-color("--ac-color-primary-300", #fac2a9);
    @include define-color("--ac-color-primary-500", #f9834c);
    @include define-color("--ac-color-primary-700", #e05c1c);

    //Secondary
    @include define-color("--ac-color-secondary-50", #eaf4f3);
    @include define-color("--ac-color-secondary-100", #049f911f);
    @include define-color("--ac-color-secondary-500", #049f91);
    @include define-color("--ac-color-secondary-700", #008171);

    //Feedback
    @include define-color("--ac-color-feedback-succes", #049f91);
    @include define-color("--ac-color-feedback-error", #e64e4e);

    //Neutral
    @include define-color("--ac-color-neutral-white", #ffffff);
    @include define-color("--ac-color-neutral-50", #f9fafa);
    @include define-color("--ac-color-neutral-200", #ebf0ef);
    @include define-color("--ac-color-neutral-300", #dae1e1);
    @include define-color("--ac-color-neutral-400", #b7c3c1);
    @include define-color("--ac-color-neutral-500", #94a8a6);
    @include define-color("--ac-color-neutral-700", #4d6663);
    @include define-color("--ac-color-neutral-900", #0d3338);

    //Transparent
    @include define-color("--ac-color-transparent", rgba(0, 0, 0, 0));

    /* ---- Shadows ---- */
    --ac-box-shadow-xs: 1px 2px 2px rgba(0, 0, 0, 0.04);
    --ac-box-shadow-sm: 0px 8px 32px -4px rgba(13, 51, 56, 0.12), 0px 0px 12px rgba(13, 51, 56, 0.12);

    /* ---- Sizes ---- */

    --ac-pixel-size-1: 0.0625rem;
    --ac-pixel-size-4: 0.25rem;
    --ac-pixel-size-7: 0.4375rem;
    --ac-pixel-size-8: 0.5rem;
    --ac-pixel-size-9: 0.5625rem;
    --ac-pixel-size-10: 0.625rem;
    --ac-pixel-size-12: 0.75rem;
    --ac-pixel-size-14: 0.875rem;
    --ac-pixel-size-16: 1rem;
    --ac-pixel-size-24: 1.5rem;
}
