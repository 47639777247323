@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-title {
    @include title-font();
    margin: 0;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    position: relative;

    &--1 {
        font-size: 3.5rem;
        line-height: 100%;
    }
}
