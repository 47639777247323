@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-tabs {
}

.ac-tab-nav {
  position: relative;
  border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);

  &__slider {
    position: absolute;
    width: var(--ac-tab-label--width);
    margin: 0;

    bottom: 0;

    border: solid var(--ac-color-secondary-500);
    border-width: var(--ac-pixel-size-1) 0;
    transition: width 0.2s ease, transform 0.2s ease;

    &--error {
      border: solid var(--ac-color-feedback-error);
      border-width: var(--ac-pixel-size-1) 0;
    }
  }
}

.ac-tab-label {
  box-sizing: content-box;
  min-height: 1.25rem;
  padding: 0.875rem 1rem;
  border: none;
  cursor: pointer;

  background-color: var(--ac-color-transparent);
  @include body-font();

  color: var(--ac-color-neutral-500);
  letter-spacing: 0.235px; // To prevent bold-shifting

  &--active {
    color: var(--ac-color-secondary-500);
    font-weight: var(--ac-typography-font-weight-semibold);
    letter-spacing: unset;
  }

  &--error {
    color: var(--ac-color-feedback-error);
  }
}
.ac-tab-panel {
  display: none;

  &--active {
    display: block;
  }
}
